import React, {useContext} from 'react'
import {NutricalRequestContext} from "./Form";
import TextField from "../components/TextField";
import Button from "../components/Button";
import "../css/productForm.css"
import Tooltip from "../components/Tooltip";
import SubscriptionSelect from "../components/SubscriptionSelect";
import {ArrowRightIcon} from "../components/Icons";

const ProductForm = ({hide, onNext, onBack, stepIndex}) => {

    const {nutricalRequest, setNutricalRequest} = useContext(NutricalRequestContext)

    if (hide) {
        return
    }

    const onSubscriptionPackageChange = (value) => {
        setNutricalRequest({
            ...nutricalRequest,
            subscription_package: value
        })
    }

    const onRecipeNameChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_name: e.target.value
        })
    }

    const onCategoryChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_category: e.target.value
        })
    }

    const onPackageTypeChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_packing_type: e.target.value
        })
    }

    const onRecipeNetAmountChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_net_amount: e.target.value
        })
    }

    const validateForm = () => {

        let validation = {
            isValid: true,
            recipe_name: {
                errorMsg: ""
            },
            recipe_category: {
                errorMsg: ""
            }
        }

        if (!nutricalRequest.recipe_name) {
            validation.isValid = false
        }


        return validation
    }

    const validation = validateForm()
    const isValid = validation.isValid

    return (
        <div className="ProductForm">
            <div className="section-id">
                <span>{stepIndex}</span>
                <span>Informacije o proizvodu</span>
            </div>

            <SubscriptionSelect selected={nutricalRequest.subscription_package}
                                onChange={onSubscriptionPackageChange}
                                className="mt-30"/>

            <div className="section flex column mt-30">



                <Tooltip  title="Naziv proizvoda" className="mb-2">
                    Navedite naziv pod kojim će hrana biti stavljena u promet, uobičajeni naziv hrane ili
                    opisni naziv hrane, npr.<b>Maffin sa bademima i malinama, Čoko štapići</b>
                </Tooltip>


                <TextField className=""
                           placeholder="Unesite naziv proizvda (npr. Čoko štapići, Maffin sa bademom, itd.)"
                           value={nutricalRequest.recipe_name}
                           onChange={onRecipeNameChange}/>

                <Tooltip title="Vrsta proizvoda" className="mt-8 mb-2" isOptional={true}>
                    Navedite grupu proizvoda kojoj pripada vaš proizvod npr. <b>kolač, čajno pecivo preliveno</b> čokoladom.
                    Ukoliko niste sigurni, ostavite ovo polje prazno, i mi ćemo to uraditi za vas!
                </Tooltip>

                <TextField className=""
                           placeholder="Unesite vrstu proizvoda (npr. desert, kolač, pecivo, itd.)"
                           value={nutricalRequest.recipe_category}
                           onChange={onCategoryChange}/>


                <Tooltip title="Način pakovanja" className="mt-8 mb-2" isOptional={true}/>

                <TextField className=""
                           placeholder="Unesite način pakovanja"
                           mandatory={false}
                           value={nutricalRequest.recipe_packing_type}
                           onChange={onPackageTypeChange}/>

                <Tooltip title="Neto količina pakovanja" className="mt-8 mb-2" isOptional={true}/>
                <TextField  className=""
                             placeholder="Unesite neto količinu u gramima, militrima ili litrima."
                             mandatory={false}
                             value={nutricalRequest.recipe_net_amount}
                             onChange={onRecipeNetAmountChange}/>

            </div>


            <div className="flex right mt-50">
                <Button className="ml-15"
                        size="large"
                        color={"orange"}
                        endIcon={<ArrowRightIcon/>}
                        onClick={onNext}
                        disabled={!isValid}>Sledeći korak</Button>
            </div>

        </div>
    )
}

export default ProductForm