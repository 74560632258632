import './App.css';

import Form from "./form/Form";
import WebFont from 'webfontloader';

import {useEffect} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import DigitalMenuPage from "./restaurant/DigitalMenuPage";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";



const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Form/>
        ),
    },
    {
        path: "/digital-menu/:uuid",
        element: (
           <DigitalMenuPage/>
        ),
    }
])

const queryClient = new QueryClient()


function App() {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Poppins:300,400,500,600,700']
            }
        });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router}/>
        </QueryClientProvider>
    );
}

export default App;
