import React from "react"
import Modal from "./Modal";
import Button from "./Button";
import {CheckIcon} from "./Icons";


const SubscriptionDetailsModal = ({isOpen, subscriptionType, onClose}) => {

    if (!isOpen) {
        return
    }

    return (
        <Modal contentClassName="w-[650px] p-8" onClose={onClose}>
            <div className="text-center text-[34px] mt-30">Detalji paketa</div>


            <div className="flex justify-between text-sm font-medium mt-12">
                <div className="flex items-baseline">
                    <CheckIcon/>
                    <div className="ml-4">{subscriptionType.label}</div>
                </div>
                <div>{subscriptionType.price} RSD</div>
            </div>

            <div className="flex justify-between mt-8 text-sm font-medium">
                <div className="flex items-baseline">
                    <CheckIcon/>
                    <div className="ml-4">Izrazi energetsku vrednost i količinu hranjivih materija po
                        porciji/potrošačkoj jedinici hrane
                    </div>
                </div>
                <div className="nowrap ml-12 text-orange">promo cena 0 RSD</div>
            </div>

            <div className="flex justify-between mt-8 text-sm font-medium">
                <div className="flex items-baseline">
                    <CheckIcon/>
                    <div className="ml-4">Izrazi procenat referentnog unosa (% RU) za 100g, po porciji/potrošačkoj
                        jedinici
                    </div>
                </div>
                <div className="nowrap ml-12 text-orange">promo cena 0 RSD</div>
            </div>

            <div className="flex justify-between bg-gray-100 rounded-md p-[10px] mt-12 font-medium">
                <div>Total:</div>
                <div>{subscriptionType.price} RSD</div>
            </div>

            <div className="flex justify-center mt-12">
                <Button color="orange"
                        size="large"
                        onClick={onClose}>U redu</Button>
            </div>
        </Modal>
    )

}

export default SubscriptionDetailsModal