import React, { useState } from 'react';
import classNames from "classnames";

const Switch = ({ onToggle, initialChecked = false }) => {
    const [checked, setChecked] = useState(initialChecked);

    const toggleSwitch = () => {
        const newValue = !checked;
        setChecked(newValue);
        onToggle(newValue);
    };

    return (
        <label className="flex items-center cursor-pointer w-[80px] h-[44px] grow-0 shrink-0">
            <div className="relative w-full h-full">
                <input type="checkbox" className="hidden" checked={checked} onChange={toggleSwitch}/>
                <div className={classNames({
                    "toggle__line w-full h-full rounded-full shadow-inner": true,
                    "bg-gray-400": !checked,
                    "bg-blue-400": checked
                })}></div>
                <div
                    className={`toggle__dot absolute w-[34px] h-[34px] bg-white rounded-full shadow inset-y-0 top-[5px] left-[5px] ${checked ? 'translate-x-full bg-blue-500' : ''}`}></div>
            </div>
        </label>
    );
};

export default Switch;