import React, {useState} from "react";
import "../css/textField.css"
import classNames from "classnames";

const TextField = ({value, onChange, label, id, className, pattern, mandatory, errorMsg, placeholder}) => {

    const [isEmpty, setIsEmpty] = useState(!value)

    const handleOnChangeEvent = (e) => {
        if (!e.target.validity.valid) {
            return
        }
        setIsEmpty(!e.target.value)
        onChange(e)
    }

    const classes = classNames(className, {
        "text-field": true,
        "empty-text-field": isEmpty
    })

    return (
        <div className={classes}>
            { label &&
                <label htmlFor={id} className="label">
                    <span>{label}</span>
                    {mandatory && <span className="mandatory">*</span>}
                </label>
            }
            <input pattern={pattern} id={id}
                   type="text"
                   value={value || ""}
                   onChange={handleOnChangeEvent}
                   autoComplete="off"
                   placeholder={placeholder}/>
            <div className="error">{errorMsg}</div>
        </div>
    )
}

export default TextField