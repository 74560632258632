import {Unit} from "../components/UnitSelect";
import {SubscriptionType} from "../components/SubscriptionSelect";


const useDefaults = () => {

    const queryParams = new URLSearchParams(window.location.search)
    let subscription = queryParams.get('subscription')

    subscription = subscription ? subscription.toUpperCase() : SubscriptionType.BASIC.name

    let stepDefault = 1;

    let nutricalRequestDefault = {
        subscription_package: subscription,
        recipe_category: "",
        recipe_name: "",
        recipe_method_preparation: "",
        ingredients: [{
            unit: Unit.G
        }, {
            unit: Unit.G
        }, {
            unit: Unit.G
        }],
        recipe_net_amount: "",
        recipe_prepared_amount: "",
        recipe_unit: Unit.G,
        customer_name: "",
        customer_address: "",
        customer_city: "",
        customer_vat_number: "",
        customer_registration_number: "",
        customer_phone: "",
        contact_name: "",
        customer_email: "",
        nutrients: [],
        recipe_packing_type: "",
        referent_entry_percentage_flag: false,
        portion_flag: false,
        portion_amount: "",
        portion_unit: Unit.G,
        nutrient_flag: {
            // vitamins
            vitamin_a: false,
            vitamin_d: false,
            vitamin_e: false,
            vitamin_k: false,
            thiamin_b1: false,
            riboflavin_b2: false,
            vitamin_b6: false,
            folate_b9: false,
            vitamin_b12: false,
            biotin_b7: false,
            pantothenic_acid_b5: false,
            // minerals
            potassium: false,
            chloride: false,
            calcium: false,
            phosphorus: false,
            iron: false,
            zinc: false,
            copper: false,
            magnesium: false,
            selenium: false,
            chromium: false,
        }
    }

    return [
        stepDefault,
        nutricalRequestDefault
    ]
}

export default useDefaults