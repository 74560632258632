import React from "react";

import "../css/steps.css"
import StepItem from "./StepItem";
import {CookieIcon, NotebookIcon, UserIcon} from "./Icons";

const Steps = ({step, setStep, showVitamins}) => {

    return (
        <div className="Steps">
            <StepItem step={step}
                      setStep={() => setStep(1)}
                      label="Korak 1"
                      subLabel="Informacije o paketu i proizvodu"
                      selected={step === 1}
                      completed={step > 1}
                      icon={<NotebookIcon width={24}/>}/>
            <div className="Step-space"></div>
            <StepItem step={step}
                      setStep={() => setStep(2)}
                      label="Korak 2"
                      subLabel="Sastav proizvoda i način pripreme"
                      selected={step === 2}
                      completed={step > 2}
                      icon={<CookieIcon width={24}/>}/>
            <div className="Step-space"></div>
            { showVitamins &&
                <StepItem step={step}
                          setStep={() => setStep(3)}
                          label="Korak 3"
                          subLabel="Vitamini i minerali"
                          selected={step === 3}
                          completed={step > 3}
                          icon={<UserIcon width={24}/>}/>
            }
            <StepItem step={step}
                      setStep={() => setStep(3)}
                      label={showVitamins ? "Korak 4" : "Korak 3"}
                      subLabel="Vaši podaci"
                      selected={step === 4}
                      isLast={true}
                      completed={step > 4}
                      icon={<UserIcon width={24}/>}/>
        </div>
    )
}

export default Steps