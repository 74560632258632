import React from "react"
import Modal from "./Modal";
import Button from "./Button";

const PageLeaveModal = ({isOpen, onClose, to}) => {

    if (!isOpen) {
        return
    }

    const linkTo = () => {
        window.location.href = `https://nutrikator.com#${to}`
    }

    return (
        <Modal contentClassName="w-[550px] h-[520px] p-8 flex flex-column items-center text-text-body" onClose={onClose} >

            <div className="flex justify-center mt-10">
                <div className="w-[90px] h-[90px] rounded-full bg-gray-100 flex items-center justify-center">
                    <img src="/icons/report.svg" alt=""/>
                </div>
            </div>

            <div className="text-[32px] text-center mt-12">Napuštanje formulara</div>
            <div className="text-[16px] text-center mt-8 ">
                <div>Da li ste sigurni da želite da napustite formular?</div>
                <div> Vaši uneti podaci neće biti sačuvani.</div>
            </div>

            <Button onClick={onClose} className="mt-12" color="orange" size="large">Ostani na formularu</Button>
            <div className=" hover:text-text-highlight mt-6 underline font-semibold font-[16px]  cursor-pointer"
                 onClick={linkTo}>Nastavi na link
            </div>
        </Modal>
    )
}

export default PageLeaveModal