import React from "react"
import Button from "./Button";

const SuccessfulSubmit = ({displaySubmitModal, backToHome, startNewRequest}) => {

    if (!displaySubmitModal) {
        return
    }

    return (
        <div className=" h-[100vh] flex items-center justify-center">
            <div className="bg-white h-[525px] w-[600px] rounded-md flex column items-center p-8">
                <div className="w-[84px] h-[84px] bg-gray-200 flex items-center justify-center rounded-full mt-8">
                    <img src="/icons/sucess_modal.svg"/>
                </div>
                <div  className="mt-14 text-[34px]">Formular uspešno poslat</div>
                <div className="mt-8 text-center">
                    Uspešno ste poslali svoj zahtev. Očekujte e-poruku na vašu adresu nakon što se zahtev prihvati i obradi
                </div>

                <Button onClick={startNewRequest} className="mt-12" color="orange" size="large">U redu</Button>
            </div>
        </div>
    )
}

export default SuccessfulSubmit