import React, {useState} from "react"
import PageLeaveModal from "./PageLeaveModal";

const NavItem = ({label, to, children}) => {

    const [isPageLeaveModalOpen, setPageLeaveModalOpen] = useState(false)

    return (
        <div>
            <div onClick={() => setPageLeaveModalOpen(true)}
                 className="ml-[40px] text-white cursor-pointer hover:underline">{children}
            </div>
            <PageLeaveModal isOpen={isPageLeaveModalOpen}
                            onClose={() => setPageLeaveModalOpen(false)}
                            to={to}/>
        </div>
    )
}

const Navigation = (props) => {

    return (
        <div className="bg-[#242424] fixed w-full z-10 grid grid-cols-[2fr,280px,repeat(9,1fr),2fr] p-[10px]">
            <div className="col-start-2">
                <img src="/Logo_Dark.svg" />
            </div>

            <div className="col-start-3 col-span-9  flex justify-end items-center">
                <NavItem to="">Početna</NavItem>
                <NavItem to="onama">O nama</NavItem>
                <NavItem to="koraci">Kako funkcioniše?</NavItem>
                <NavItem to="paketi">Paketi</NavItem>
                <div className="ml-[40px] text-white cursor-pointer hover:underline"><a href="#contact">Kontakt</a></div>
            </div>
        </div>
    )
}

export default Navigation