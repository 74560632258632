import React, {useState} from "react";

import "../css/subscriptionCard.css"
import classNames from "classnames";
import Button from "./Button";

export const SubscriptionType = {
    BASIC: "BASIC",
    STANDARD: "STANDARD",
    PREMIUM: "PREMIUM",
    DECLARATION: "DECLARATION"
}

const SubscriptionCard = ({type, selected, onSelect}) => {

    const [expanded, setExpanded] = useState(false)

    const classes = classNames({
        "SubscriptionCard": true,
        "selected": selected,
        "expanded": expanded
    })

    const expand = (e) => {
        e.stopPropagation()
        setExpanded(true)
    }

    const shrink = (e) => {
        e.stopPropagation()
        setExpanded(false)
    }

    const nextStep = () => {

    }

    return (
        <div className={classes}>
                {
                    type === SubscriptionType.BASIC &&
                    <div className="basic">
                        <div className="img"><img src="/icons/subsc_basic.svg"/></div>
                        <div className="title fc-primary">Basic</div>
                        <div className="sub-title fc-dark-gray">
                            Savršen paket za one
                            koji žele samo nutritivnu tablicu.
                        </div>
                        <div className="price">
                            <div>RSD</div>
                            <div>4,000</div>
                        </div>
                        <div className="description">
                            <div className="fc-dark-gray">
                                Ukoliko izaberete ovaj paket,
                                izracunaćemo energetsku i nutritivnu vrednost vašeg proizvoda
                                i navesti njegov sastava i obeležiti alergene u skladu sa važećom regulativom.
                            </div>
                        </div>
                        <div className="footer">
                            {!expanded && <Button onClick={expand} color="white" endIcon={<img src="/icons/down.svg"/>}>Detalji</Button>}
                            {expanded && <Button onClick={shrink} color="white" endIcon={<img src="/icons/up.svg"/>}>Zatvori</Button>}
                            <Button className="ml-5" color={"secondary"} endIcon={<img src="icons/next.svg"/>} onClick={onSelect}>Odaberi</Button>
                        </div>
                    </div>
                }

                {
                    type === SubscriptionType.STANDARD &&
                    <div className="standard">
                        <div className="img"><img src="/icons/subsc_standard.svg"/></div>
                        <div className="title fc-primary">Standard</div>
                        <div className="sub-title fc-dark-gray">
                            Savršen paket za one
                            koji žele deklaraciju
                            i nutritivnu tablicu.
                        </div>
                        <div className="price">
                            <div>RSD</div>
                            <div>6,000</div>
                        </div>
                        <div  className="description">
                            <div className="fc-dark-gray">
                                Ukoliko izaberete ovaj paket izracunaćemo energergetsku i nutritivnu vrednost vašeg proizvoda
                                i izraditi predlog deklaracije
                                u skladu sa zahtevima važeće regulative.
                            </div>

                            <ul className="fc-dark-gray">
                                <li> Propisani naziv proizvoda
                                </li>
                                <li> Spisak sastojaka proizvoda prema opadajucem redosledu zastupljenosti sa adekvatno
                                    obelezenim alergenima
                                </li>
                                <li> Svi ostali obavezni elementi deklaracije, uklucujuci i nutritivnu tabelu sa obaveznim
                                    elementima
                                </li>
                            </ul>

                        </div>
                        <div className="footer">
                            {!expanded && <Button onClick={expand} color="white" endIcon={<img src="/icons/down.svg"/>}>Detalji</Button>}
                            {expanded && <Button onClick={shrink} color="white" endIcon={<img src="/icons/up.svg"/>}>Zatvori</Button>}
                            <Button className="ml-5" color={"secondary"} endIcon={<img src="icons/next.svg"/>} onClick={onSelect}>Odaberi</Button>
                        </div>
                    </div>
                }

                {
                    type === SubscriptionType.PREMIUM &&
                    <div className="premium">
                        <div className="img"><img src="/icons/subsc_premium.svg"/></div>
                        <div className="title fc-white">Premium</div>
                        <div className="sub-title fc-medium-gray">
                            Savršen paket za one koji žele više od obavezne delaracije
                        </div>
                        <div className="price">
                            <div className="fc-white">RSD</div>
                            <div className="fc-white">8,000</div>
                        </div>
                        <div  className="description">
                            <div className="fc-medium-gray">
                                Ukoliko izaberete ovaj paket izračunaćemo energetsku i nutritivnu vrednost Vašeg proizvoda, ali
                                i dodatne elemente nutritivne deklaracije (masne kiseline, skrob, polioli, vlakna, vitamine,
                                minerale) i izraditi predlog deklaracije u skladu sa zahtevima važeće regulative:
                            </div>
                            <ul className="fc-medium-gray">
                                <li>Propisani naziv proizvoda</li>
                                <li>Sastav proizvoda sa adekvatno označenim alergenima</li>
                                <li>Nutritivna tabela sa osnovnim, obaveznim elementima i dodatmim elementima koje ste
                                    izabrali
                                </li>
                                <li>Dobrovoljne nutritivne izjave koje proizilaze iz dobijenih vrednosti (npr "visok sadržaj
                                    vlakana", "izvor proteina","bogato vitaminom C" i sl.)
                                </li>

                            </ul>
                        </div>
                        <div className="footer">
                            {!expanded && <Button onClick={expand} color="black" endIcon={<img src="/icons/down-gray.svg"/>}>Detalji</Button>}
                            {expanded && <Button onClick={shrink} color="black" endIcon={<img src="/icons/up.svg"/>}>Zatvori</Button>}
                            <Button className="ml-5" color={"orange"}>USKORO</Button>
                        </div>
                    </div>
                }
        </div>
    )
}

export default SubscriptionCard