import React, {useState} from "react"
import "../css/subscriptionSelect.css"
import classNames from "classnames";
import {CaretDownIcon, CaretUpIcon} from "./Icons";

export const SubscriptionType = {
    BASIC: {
        name: "BASIC",
        label: "Basic",
        price: "4.000"
    },
    STANDARD: {
        name: "STANDARD",
        label: "Standard",
        price: "6.000"
    },
    PREMIUM: {
        name: "PREMIUM",
        label: "Premium",
        price: "12.000"
    },
}


export const getSubscriptionType = (selected) => {


    if (selected && selected.toLowerCase() === SubscriptionType.PREMIUM.name.toLowerCase()) {
        return SubscriptionType.PREMIUM
    }

    if (selected && selected.toLowerCase() === SubscriptionType.STANDARD.name.toLowerCase()) {
        return SubscriptionType.STANDARD
    }

    return SubscriptionType.BASIC
}

const SubscriptionSelect = ({selected, onChange, className}) => {

    const [displayOptions, setDisplayOptions] = useState(false)

    const subscriptionType = getSubscriptionType(selected)

    const toggleOptions = () => {
        setDisplayOptions(!displayOptions)
    }

    const subscriptionClassNames = classNames("SubscriptionSelect", className)

    const selectPackage = (value) => {
        onChange(value)
        setDisplayOptions(false)
    }

    return (
        <div className={subscriptionClassNames}>
            <div className="select" onClick={toggleOptions}>
                <div className="label">{subscriptionType.label}</div>
                <div className="price">{subscriptionType.price} RSD</div>
                {!displayOptions && <CaretDownIcon className="caret"/>}
                {displayOptions && <CaretUpIcon className="caret"/>}
            </div>
            {
                displayOptions &&
                <div className="options">
                    <div className={subscriptionType === SubscriptionType.BASIC ? 'option selected' : 'option' }
                         onClick={() => selectPackage(SubscriptionType.BASIC.name)}>
                        <div>{SubscriptionType.BASIC.label}</div>
                        <div>{SubscriptionType.BASIC.price} RSD</div>
                    </div>
                    <div className={subscriptionType === SubscriptionType.STANDARD ? 'option selected' : 'option' }
                         onClick={() => selectPackage(SubscriptionType.STANDARD.name)}>
                        <div>{SubscriptionType.STANDARD.label}</div>
                        <div>{SubscriptionType.STANDARD.price} RSD</div>
                    </div>
                    <div className={subscriptionType === SubscriptionType.PREMIUM ? 'option selected' : 'option' }
                         onClick={() => selectPackage(SubscriptionType.PREMIUM.name)}>
                        <div>{SubscriptionType.PREMIUM.label}</div>
                        <div>{SubscriptionType.PREMIUM.price} RSD</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SubscriptionSelect