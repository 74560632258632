import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {ArrowLeftIcon, BackArrowIcon, CheckCircleGreenIcon, MissingImg, SearchIcon} from "../components/Icons";
import Switch from "../components/Switch";
import {useDigitalMenuStore} from "../store/digitalMenuStore";
import TrafficLight from "../components/TrafficLight";
import AllergenTag from "./AllergenTag";
import {useMediaStore} from "../store/mediaStore";


const CharacteristicTag = ({label}) => {
    return (
        <div
            className="flex items-center h-[45px] nowrap rounded-[30px] bg-white pl-2 pr-4 mr-2 shadow shadow-gray-200">
            <CheckCircleGreenIcon/>
            <div className="ml-1 text-[18px]">{label}</div>
        </div>
    )
}

const MenuItemThumbnail = ({restaurant, recipeName, price, imgSrc, onClick}) => {

    return (
        <div className="mb-4 mx-4 grow " onClick={onClick}>
            <div
                className="bg-gray-300 rounded-[16px] h-[150px] overflow-hidden shadow border  hover:border-green-700 hover:cursor-pointer flex items-center justify-center">
                <Cover uuid={restaurant?.cover_storage?.id}/>
                {/*{!imgSrc && <div className="p-6"><MissingImg/></div>}*/}
            </div>
            <div className="mt-3">
                <div className="text-[18px] font-bold">{recipeName}</div>
                {price && <div className="text-sm">{price} RSD</div>}
            </div>
        </div>
    )
}

const MenuItemHeader = () => {

    return (
        <div>

        </div>
    )
}

const Cover = ({uuid, showDefaultImg= true}) => {

    const {data: imageData, isLoading, isError} = useMediaStore(uuid);

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error fetching image</div>;

    if (uuid && imageData) {
        const imageUrl = URL.createObjectURL(imageData);

        return (
            <img src={imageUrl} alt="Image" className="object-cover w-full h-full"/>
        );
    }

    if (showDefaultImg ) {
        return <img src="/icons/plate_fork_and_knife.svg" alt="Image" className="object-fill h-full p-4"/>
    }
}

const DigitalMenuPage = () => {

    const {uuid} = useParams();
    const {data} = useDigitalMenuStore(uuid)
    const [menuItem, setMenuItem] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [portion, setPortion] = useState(true)


    const categoryMap = data?.digital_menu_items
        ?.filter(item => item.recipe_name?.toLowerCase()?.includes(searchText?.toLowerCase()))
        .reduce((acc, item) => {
            const category = item.category ? item.category : 'Razno'
            const categoryArray = acc[category] || []
            categoryArray.push(item)

            return {...acc, [category]: categoryArray}
        }, {})

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling
        });
    };

    const handleMenuItemSelect = (item) => {
        setMenuItem(item)
        scrollToTop()
    }

   console.log(categoryMap)

    return (
        <div className="">
            {
                menuItem &&
                <div className="relative">

                    <div className="h-[300px] bg-gray-300 relative flex items-center justify-center">
                        <Cover uuid={menuItem?.cover_storage?.id}/>
                    </div>

                    <div
                        className="z-2 absolute top-[20px] left-4  flex items-center hover:underline hover:cursor-pointer bg-gray-500 opacity-90 rounded-[12px] pl-2 pr-4 py-1"
                        onClick={() => {
                            setMenuItem(null)
                            setPortion(true)
                        }}>
                        <BackArrowIcon/>
                        <div className="ml-2 text-[20px] text-white font-bold">Nazad</div>
                    </div>

                    <div className="mt-8 px-4">
                        <div className="text-[26px] font-semibold">{menuItem?.recipe_name}</div>
                        <div className="flex items-center justify-between text-[22px] mt-2">
                            {menuItem?.price && <div>{menuItem?.price} RSD</div>}
                            <div className="font-semibold">{menuItem?.portion_amount} g
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center px-4 mt-6">
                        {menuItem?.other_characteristics?.map((label) =>
                            <CharacteristicTag label={label}/>
                        )
                        }
                    </div>

                    <div className="px-4 mt-6">
                        <div className="text-[18px]">Sastojci:</div>
                        <div dangerouslySetInnerHTML={{__html: menuItem?.ingredients_text}}
                             className="text-[18px] bg-white p-4 rounded-[12px] mt-2 shadow-2xl shadow-gray-200">

                        </div>
                    </div>

                    {menuItem?.allergens?.length > 0 &&
                        <div className="px-4 mt-6">
                            <div className="text-[18px]">Alergeni:</div>
                            <div className="flex flex-wrap">
                                {menuItem?.allergens?.map(allergen => (

                                    <AllergenTag code={allergen.code}/>
                                ))
                                }
                            </div>
                        </div>
                    }

                    <div className="flex items-center justify-between mt-6 px-4">
                        <div className="text-[22px]">Prikaži na 100g</div>
                        <Switch initialChecked={!portion} onToggle={() => setPortion(!portion)}/>
                    </div>

                    <div className="px-4">
                        <table
                            className="w-full border-collapse mt-6 border-none bg-white rounded-[12px] overflow-hidden">
                            <thead className="bg-thead text-white">
                            <tr>
                                <td className="px-2 ">Prosečna hranljiva vrednost</td>
                                {
                                    portion && <>
                                        <td className="px-2 text-right">Porcija {menuItem.portion_amount}g</td>
                                        {menuItem?.print_percentages_flag && <td className="px-2 text-center">RU%</td>}
                                    </>
                                }
                                {
                                    !portion && <>
                                        <td className="px-2 text-right">100g</td>
                                        {menuItem?.print_percentages_flag && <td className="px-2 text-center">RU%</td>}
                                    </>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="px-4 py-2">Energija</td>
                                {portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">
                                        <div>{menuItem?.energy_portion_kj}kJ</div>
                                        <div>{menuItem?.energy_portion_kcal}kcal</div>
                                    </td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.energy_portion_percentage}%</td>}
                                </>
                                }
                                {!portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">
                                        <div>{menuItem?.energy_kj}kJ</div>
                                        <div>{menuItem?.energy_kcal}kcal</div>
                                    </td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.energy_percentage}%</td>}
                                </>
                                }
                            </tr>
                            <tr>
                                <td className="px-4 py-2">Masti</td>
                                {portion &&
                                    <>
                                        <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.fat_portion}g</td>
                                        {menuItem?.print_percentages_flag &&
                                            <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.fat_portion_percentage}%</td>}
                                    </>
                                }
                                {!portion &&
                                    <>
                                        <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.fat}g</td>
                                        {menuItem?.print_percentages_flag &&
                                            <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.fat_percentage}%</td>}
                                    </>
                                }
                            </tr>
                            <tr>
                                <td className="px-4 py-2">Od kojih zasićene masne kiseline</td>
                                {portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.fat_saturated_portion}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.fat_saturated_portion_percentage}%</td>}
                                </>
                                }
                                {!portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.fat_saturated}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.fat_saturated_percentage}%</td>}
                                </>
                                }
                            </tr>
                            <tr>
                                <td className="px-4 py-2">Ugljeni hidrati</td>
                                {portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.carbohydrates_portion}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.carbohydrates_portion_percentage}%</td>}
                                </>
                                }
                                {!portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.carbohydrates}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.carbohydrates_percentage}%</td>}
                                </>
                                }
                            </tr>
                            <tr>
                                <td className="px-4 py-2">Od kojih šećeri</td>
                                {portion &&
                                    <>
                                        <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.sugar_portion}g</td>
                                        {menuItem?.print_percentages_flag &&
                                            <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.sugar_portion_percentage}%</td>}
                                    </>
                                }
                                {!portion &&
                                    <>
                                        <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.sugar}g</td>
                                        {menuItem?.print_percentages_flag &&
                                            <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.sugar_percentage}%</td>}
                                    </>
                                }
                            </tr>
                            <tr>
                                <td className="px-4 py-2">Proteini</td>
                                {portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.protein_portion}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.protein_portion_percentage}%</td>}
                                </>
                                }
                                {!portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.protein}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.protein_percentage}%</td>}
                                </>
                                }
                            </tr>
                            <tr>
                                <td className="px-4 py-2">So</td>
                                {portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.salt_portion}g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.salt_portion_percentage}%</td>}
                                </>
                                }
                                {!portion && <>
                                    <td className="px-4 py-2 text-right font-semibold nowrap">{menuItem?.salt} g</td>
                                    {menuItem?.print_percentages_flag &&
                                        <td className="px-4 py-2 text-center font-semibold nowrap">{menuItem?.salt_percentage}%</td>}
                                </>
                                }
                            </tr>
                            </tbody>
                        </table>
                        <div className="text-md opacity-80 mt-2">
                            Referentni unos za prosečnu odraslu osobu
                            (8.400kj / 2.000kcal)
                        </div>
                    </div>

                    {/*Vitamins and minerals*/}
                    <div className="px-4 mt-6">
                        <div className="bg-white rounded-[12px]">
                            {
                                menuItem?.vitamins_minerals?.map(item => (
                                    <div
                                        className="flex justify-between items-center h-[45px] nowrap px-4 mt-2">
                                        <div className="flex items-center">
                                            <CheckCircleGreenIcon/>
                                            <div className="ml-2 text-[18px]">{item.nutrient_statement}</div>
                                        </div>
                                        <div className="flex items-center">
                                            <div
                                                className="ml-4 text-[16px] font-semibold">{item.digital_menu_statement_value}</div>
                                            <div
                                                className=" text-[16px] lowercase font-semibold">
                                                {item.digital_menu_statement_unit === "UG" ? 'µg' : item.digital_menu_statement_unit}
                                            </div>
                                            <div
                                                className="ml-4 text-[14px] opacity-70">**{item.digital_menu_statement_percentage}%
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {menuItem?.vitamins_minerals?.length > 0 &&
                            <div className="text-md opacity-80 mt-2">**Nutritivna referentna vrednost</div>}
                    </div>


                    {/*Nutrient statements*/}
                    <div className="px-4 mt-6">
                        <div className="bg-white rounded-[12px] shadow shadow-gray-200">
                            {menuItem?.nutrient_statements?.map(statement =>
                                <div
                                    className="flex items-center h-[45px] nowrap px-4">
                                    <CheckCircleGreenIcon/>
                                    <div className="ml-1 text-[18px]">{statement}</div>
                                </div>
                            )
                            }
                        </div>
                    </div>

                    <TrafficLight value={menuItem}
                                  portionAmount={menuItem?.portion_amount}/>

                </div>
            }
            {!menuItem &&
                <div className="flex flex-col justify-center items-center ">

                    <div className="h-[200px] w-full relative shrink-0 flex flex-col items-center justify-center"
                         style={{backgroundColor: data?.background_color}}>

                        <Cover uuid={data?.cover_storage?.id} showDefaultImg={false}/>

                        <div className="h-[150px] p-4  absolute  top-0 flex items-center justify-center">
                            <Cover uuid={data?.logo_storage?.id} showDefaultImg={false}/>
                        </div>

                        <div className="absolute bottom-[-30px] w-full  grow  h-[77px] ">
                            <div className="bg-white rounded-[12px] mx-8 h-full flex items-center px-4">
                                <SearchIcon className="absolute left-[55px]"/>
                                <input className="h-[42px] rounded-[6px] bg-gray-200 w-full pl-[30px] pr-4"
                                       value={searchText}
                                       onChange={e => setSearchText(e.target.value)}
                                       placeholder="Pretraži"/>
                            </div>
                        </div>
                    </div>


                    <div className="mt-8">
                        {
                            categoryMap && Object.keys(categoryMap).map(key => (
                                <div>
                                    <div
                                        className="text-[28px] font-bold mt-4 px-8 py-0 my-0">{key !== null ? key : 'Razno'}</div>
                                    <div className="grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4 max-w-[800px]">
                                        {
                                            categoryMap[key]?.map((item, index) => (
                                                <MenuItemThumbnail onClick={() => handleMenuItemSelect(item)}
                                                                   restaurant={item}
                                                                   recipeName={item.recipe_name}
                                                                   price={item.price}/>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>


                </div>
            }
        </div>
    )
}

export default DigitalMenuPage