import React, {useState} from "react";
import classNames from "classnames";

import "../css/unitSelect.css"
import {CaretDownIcon} from "./Icons";

export const Unit = {
    G: "G",
    ML: "ML",
    PCS: "PCS",
}

const UnitSelect = (props) => {

    const {value, onChange, label, id, className} = props
    const [isEmpty, setIsEmpty] = useState(!value)

    const handleOnChangeEvent = (e) => {
        setIsEmpty(!e.target.value)
        onChange(e)
    }

    const classes = classNames(className, {
        "UnitSelect": true,
        "empty": isEmpty
    })

    return (
        <div className={classes}>
            <label htmlFor={id} className="label">{label}</label>
            {!props.children &&
                <select name="" id={id} value={value} onChange={handleOnChangeEvent}>
                    <option value={Unit.G}>Gram (g)</option>
                    <option value={Unit.ML}>Mililitar (ml)</option>
                    <option value={Unit.PCS}>Komad</option>
                </select>
            }
            {
                props.children &&
                <select name="" id={id} value={value} onChange={handleOnChangeEvent}>
                    {props.children}
                </select>
            }
            <CaretDownIcon/>
        </div>
    )
}

export default UnitSelect