import React from "react";

export const Allergens = {
    CELERY: {
        label: "Celer",
        key: "CELERY",
        color: "#155A2C",
        img: <img alt="" src="/icons/allergens/celer.svg"/>
    },
    CRUSTACEANS: {
        label: "Rakovi",
        key: "CRUSTACEANS",
        color: "#88131A",
        img: <img alt="" src="/icons/allergens/rakovi.svg"/>
    },
    EGGS: {
        label: "Jaja",
        key: "EGGS",
        color: "#FEE77D",
        img: <img alt="" src="/icons/allergens/jaja.svg"/>
    },
    FISH: {
        label: "Riba",
        key: "FISH",
        color: "#42BCEB",
        img: <img alt="" src="/icons/allergens/riba.svg"/>
    },
    GLUTEN: {
        label: "Gluten",
        key: "GLUTEN",
        color: "#F8931D",
        img: <img alt="" src="/icons/allergens/gluten.svg"/>
    },
    LUPIN: {
        label: "Lupin",
        key: "LUPIN",
        color: "#2B2410",
        img: <img alt="" src="/icons/allergens/lupin.svg"/>
    },
    MILK: {
        label: "Mleko",
        key: "MILK",
        color: "#90A8D8",
        img: <img alt="" src="/icons/allergens/mleko.svg"/>
    },
    MOLLUSCS: {
        label: "Mekušci",
        key: "MOLLUSCS",
        color: "#7C7C74",
        img: <img alt="" src="/icons/allergens/mekusci.svg"/>
    },
    MUSTARD: {
        label: "Slačica",
        key: "MUSTARD",
        color: "#DAA53F",
        img: <img alt="" src="/icons/allergens/senf.svg"/>
    },
    NUTS: {
        label: "Orašasti plodovi",
        key: "NUTS",
        color: "#784F19",
        img: <img alt="" src="/icons/allergens/orasasti.svg"/>
    },
    PEANUTS: {
        label: "Kikiriki",
        key: "PEANUTS",
        color: "#5E3E29",
        img: <img alt="" src="/icons/allergens/kikiriki.svg"/>
    },
    SESAME_SEEDS: {
        label: "Seme susama",
        key: "SESAME_SEEDS",
        color: "#9F8864",
        img: <img alt="" src="/icons/allergens/susam.svg"/>
    },
    SOYBEANS: {
        label: "Soja",
        key: "SOYBEANS",
        color: "#6AB563",
        img: <img alt="" src="/icons/allergens/soja.svg"/>
    },
    SULPHURE_DIOXIDE_SULPHITES: {
        label: "Sumpor Dioksid i sulfiti",
        key: "SULPHURE_DIOXIDE_SULPHITES",
        color: "#6F1356",
        img: <img alt="" src="/icons/allergens/sulfati.svg"/>
    },
};

const AllergenTag = ({code}) => {

    console.log(code)


    return (
        <div className="bg-white shadow shadow-gray-200 p-4 rounded-[12px] flex flex-col justify-center items-center mt-2 mr-2">
            <div className="bg-red-200 w-[64px] h-[64px] rounded-full flex items-center justify-center"
            style={{backgroundColor: Allergens?.[code]?.color}}>{Allergens?.[code]?.img}</div>
            <div className="font-semibold">{Allergens?.[code]?.label}</div>
        </div>
    )
}

export default AllergenTag