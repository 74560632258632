import React from "react"

import "../css/checkbox.css"
import classNames from "classnames";

const Checkbox = ({checked, onChange, className, children}) => {

    const classes = classNames("checkbox", className)

    return (
        <label className={classes}>
            <input type="checkbox" checked={checked} onChange={onChange}/>
            <span className="mark"><img width={13} src="/icons/checkbox.svg"/></span>
            { children }
        </label>
    )
}

export default Checkbox