import {useQuery} from "@tanstack/react-query";
import {getDigitalMenuByExternalUUID} from "../services/DigitalMenuService";

export const useDigitalMenuStore = (uuid) => {
    return useQuery({
        queryKey: ['digital-menu', uuid],
        queryFn: () => getDigitalMenuByExternalUUID(uuid).then((response) => response.data),
        enabled: !!uuid,
        cacheTime: 300000
    })
}