import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "../css/button.css"


const Button = (props) => {

    let {color, size, startIcon, endIcon, selected, className, disabled} = props

    let btnClassNames = classNames(className, {
        'btn': true,
        [`btn-${size}`]: true,
        [`btn-${color}`]: true,
        'btn-selected': selected,
        'btn-disabled': disabled,
    })

    return (
            <div className={btnClassNames}>
                <button  onClick={props.onClick} disabled={disabled}>
                    {startIcon && <div className="btn-start-icon">{startIcon}</div>}
                    <div className="btn-text">{props.children}</div>
                    {endIcon && <div className="btn-end-icon">{endIcon}</div>}
                </button>
            </div>

    )
}

Button.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'submit', 'white', 'red', 'black']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'black']),
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    selected: PropTypes.bool
}

Button.defaultProps = {
    color: "primary",
    size: "medium",
    startIcon: null,
    endIcon: null,
    selected: false
}

export default Button;