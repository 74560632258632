import React from "react"

export const CookieIcon = (props) => <img {...props} src={"/icons/cookie.svg"}/>
export const NotebookIcon = (props) => <img {...props} src="/icons/notebook.svg"/>
export const UserIcon = (props) => <img {...props} src="/icons/user.svg"/>
export const CheckCircleIcon = (props) => <img {...props} src="/icons/check-circle.svg"/>
export const InfoIcon = (props) => <img {...props} src="/icons/info.svg"/>
export const CaretDownIcon = (props) => <img {...props} src="/icons/caret-down.svg"/>
export const CaretUpIcon = (props) => <img {...props} src="/icons/caret-up.svg"/>
export const ArrowRightIcon = (props) => <img {...props} src="/icons/arrow-right.svg"/>
export const ArrowLeftIcon = (props) => <img {...props} src="/icons/arrow-left.svg"/>
export const PlusCircleIcon = (props) => <img {...props} src="/icons/plus-circle.svg"/>
export const UploadIcon = (props) => <img {...props} src="/icons/upload.svg"/>
export const XCircleIcon = (props) => <img {...props} src="/icons/x-circle.svg"/>
export const XCircleRedIcon = (props) => <img {...props} src="/icons/x-circle-red.svg"/>
export const SendIcon = (props) => <img {...props} src="/icons/send.svg"/>
export const CheckIcon = (props) => <img {...props} src="/icons/checkbox.svg"/>
export const XIcon = (props) => <img {...props} src="/icons/x.svg"/>

export const MissingImg = (props) => <img {...props} src="/icons/missingImg.svg"/>

export const SearchIcon = (props) => <img {...props} src="/icons/searchIcon.svg"/>

export const CheckCircleGreenIcon = (props) => <img {...props} src="/icons/checkCircleIcon.svg"/>

export const BackArrowIcon = (props) => <img {...props} src="/icons/backArrowIcon.svg"/>



