import React from "react";

const Checkmark = ({checked}) => {

    return (
        <div className={`w-[24px] h-[24px] rounded-[6px] ${checked ? 'bg-checked' : 'bg-unchecked'}  
        flex items-center justify-center`}>
            {checked && <img fill="white" width={13} src="/icons/checkbox.svg"/>}
        </div>
    )
}

export default Checkmark