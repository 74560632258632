import React from "react";

import "../css/stepItem.css"
import classNames from "classnames";
import {CheckCircleIcon} from "./Icons";

const StepItem = ({label, subLabel, selected, completed, isLast, icon}) => {


    const iconClasses = classNames({
        "icon": true,
        "icon-selected": selected
    })

    const labelClasses = classNames({
        "fc-light": !selected
    })

    const classes = classNames({
        "StepItem": true,
        "selected": selected,
        "completed": completed
    })

    return (
        <div className={classes}>
            <div className="content">
                <div>
                    <div className={iconClasses}>
                        {!completed && icon}
                        {completed && <CheckCircleIcon/>}
                    </div>
                </div>
                <div>
                    <div className={`fs-12 label ${labelClasses}`}>{label}</div>
                    <div className={`fs-16 sub-label ${labelClasses}`}>{subLabel}</div>
                </div>
            </div>
            { !isLast &&
                <div className="line">
                    <div></div>
                </div>
            }
        </div>
    )
}


export default StepItem