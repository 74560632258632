import React from "react"
import classNames from "classnames";
import Checkbox from "./Checkbox";


const Colors = {
    AMBER: "#EB9C02",
    GREEN: "#67BE01",
    RED: "#E81111",
    WHITE: "#FFFFFF"
}

const Nutrient = ({title, color, children, percentage, className}) => {

    const styles = {
        backgroundColor: Colors[color]
    }

    const classes = classNames(className,
        "w-[68px] h-[82px] sm:w-[90px] sm:h-[110px] bg-red flex flex-col items-center space-between border-[3px] rounded-[25px] sm:rounded-[30px] border-gray-800 p-[4px]")

    return (
        <div className={classes} style={styles}>
            <div className={`text-xs font-medium mt-1 text-opacity-80`} style={{color: color === "WHITE" ? '#818181' : 'rgb(255 255 255 / 0.8)'}}>{title}</div>
            <div className="grow">{children}</div>
            <div className={`w-full h-[22px] sm:h-[35px] rounded-b-[26px] rounded-t-[4px] bg-white bg-opacity-80 
            flex items-center justify-center border 
            ${color=== 'WHITE' ? 'border-traffic-light' : 'border-transparent'}`}>
                <div className="flex items-baseline">
                    <div className=" font-medium text-[18px] sm:text-[24px] flex items-center">{percentage}</div>
                    <div className="font-medium text-[12px] ml-[1px] relative bottom-[3px]">%</div>
                </div>
            </div>
        </div>
    )
}

const TrafficLight = ({value, portionAmount, unit, energy, reportFlag, onReportFlagChange}) => {

    if (!value) {
        return <></>
    }

    const unitLabel = unit ? unit.toLowerCase() : 'g'

    return (
        <div className="w-full">
            <div className="flex items-center bg-white mt-6 w-full">
                <div className="flex flex-col justify-center align-center bg-section-header rounded-[8px] grow">
                    <div className="mt-4 mb-4 font-medium text-[14px] text-center">
                        {portionAmount && `Porcija proizvoda (${portionAmount}${unitLabel}) sadrži:`}
                        {!portionAmount && `100${unitLabel} proizvoda sadrži:`}
                    </div>
                    <div className="flex flex-wrap justify-center items-center">
                        <Nutrient title="Energija"
                                  color={value?.traffic_light_energy_color}
                                  percentage={value?.traffic_light_energy_percentage}>
                            <div className="flex flex-col justify-center items-center font-medium text-[14px] leading-tight h-full">
                                <div className="text-[11px] sm:text-[14px] nowrap"> {value?.traffic_light_energy.split(",")[0]} kJ</div>
                                <div className="text-[11px] sm:text-[14px] nowrap"> {value?.traffic_light_energy.split(",")[1]} kcal</div>
                            </div>
                        </Nutrient>
                        <Nutrient title="Masti" color={value?.traffic_light_fat_color}
                                  percentage={value?.traffic_light_fat_percentage}
                                  className="relative left-[-3px] font-medium">
                            <span className="text-[18px] sm:text-[24px] text-white">{value?.traffic_light_fat}</span>
                            <span className="text-[14px] sm:text-[16px] ml-[2px] text-white">g</span>
                        </Nutrient>
                        <Nutrient title="Zasićene" color={value?.traffic_light_fat_saturated_color}
                                  percentage={value?.traffic_light_fat_saturated_percentage}
                                  className="relative left-[-6px] font-medium">
                            <span className="text-[18px] sm:text-[24px] text-white ">{value?.traffic_light_fat_saturated}</span>
                            <span className="text-[14px] sm:text-[16px] ml-[2px] text-white">g</span>
                        </Nutrient>
                        <Nutrient title="Šećeri" color={value?.traffic_light_sugar_color}
                                  percentage={value?.traffic_light_sugar_percentage}
                                  className="relative left-[-9px] font-medium">
                            <span className="text-[18px] sm:text-[24px] text-white ">{value?.traffic_light_sugar}</span>
                            <span className="text-[14px] sm:text-[16px] ml-[2px] text-white">g</span>
                        </Nutrient>
                        <Nutrient title="So" color={value?.traffic_light_salt_color}
                                  percentage={value?.traffic_light_salt_percentage}
                                  className="relative left-[-12px] font-medium">
                            <span className="text-[18px] sm:text-[24px]  text-white ">{value?.traffic_light_salt}</span>
                            <span className="text-[14px] sm:text-[16px] ml-[2px] text-white">g</span>
                        </Nutrient>
                    </div>
                    <div className="font-medium text-[14px] mt-4 text-center mb-8">od preporučenog referentnog unosa za odraslu osobu</div>

                   {/* {
                        portionAmount &&
                        <div className=" text-[14px] mt-4 text-center">
                            <span>Prosečna energetska vrednost 100g proizvoda: </span>
                            <span className="font-medium">
                            <span>Energija</span>
                            <span> {energy?.nutrient_value.split(",")[0]} kJ</span>
                            <span> / </span>
                            <span> {energy?.nutrient_value.split(",")[1]} kcal</span>
                        </span>
                        </div>
                    }*/}
                </div>
            </div>
        </div>
    )
}

export default TrafficLight